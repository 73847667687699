.bazis-checkbox {
    .bazis-list_vline .bazis-control & {
        margin-left: var(--bazis-margin-3x);
    }

    .sl-item--selected_partial & {
        &__label::before {
            content: '—';
            border-color: var(--border-color-checked);
            background: var(--background-checked);

            color: var(--checkmark-color);
        }
    }

    .sl-col_selectable .bazis-control & {
        &__label::before {
            margin-right: var(--bazis-margin-6x);
        }
    }

    @media (max-width: 1024px) {
        .sl-toolbar_table-header .bazis-list_vline .bazis-control & {
            margin-left: 0;
        }
    }

    &.bazis-item--disabled > .bazis-checkbox__label {
        pointer-events: none;
        color: var(--color-disabled);

        &::before {
            opacity: var(--disabled-opacity);
        }
    }
    &.bazis-item--disabled:not(.bazis-item--selected) > .bazis-checkbox__label {
        &::before {
            content: '';
            background: var(--background-disabled);
            border-color: var(--border-color-disabled);
        }
    }
}
