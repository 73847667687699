bazis-grid.sl-grid_table {
    --bazis-grid-columns: 24;
    margin-bottom: var(--bazis-margin-6x);

    &.bh-no-margin {
        margin-bottom: 0;
    }

    > bazis-row,
    *:not(bazis-row) bazis-row {
        &:not(.bh-no-border) {
            border-bottom: 1px solid var(--bazis-border-color);
        }

        > bazis-col {
            --bazis-grid-column-padding-start: var(--bazis-padding-3x);
            --bazis-grid-column-padding-end: var(--bazis-padding-3x);
            --bazis-grid-column-padding-top: var(--bazis-padding-3x);
            --bazis-grid-column-padding-bottom: var(--bazis-padding-3x);

            line-height: 20px;

            &.sl-col_th {
                --bazis-grid-column-padding-top: 7px;
                --bazis-grid-column-padding-bottom: 7px;

                background: var(--bazis-background-medium);

                font-size: 13px;
                font-weight: 500;
                text-align: left;
                color: var(--bazis-text-color-secondary);

                &.sl-col_selectable {
                    --bazis-grid-column-padding-start: 52px;
                }

                &.sl-col_sortable {
                    bazis-button {
                        margin-top: calc(var(--bazis-margin-2x) * -1);
                    }
                }
            }

            &.sl-table__cell-btns {
                --bazis-grid-column-padding-top: 7px;
            }
        }

        &.sl-row_thead {
            border-bottom: 0;

            &.sl-th_no-padding > bazis-col,
            > bazis-col.sl-th_no-padding {
                --bazis-grid-column-padding-bottom: 0;
            }
            &.sl-th_no-padding + & > bazis-col {
                --bazis-grid-column-padding-top: 0;
            }
        }

        &.bazis-td-no-border {
            border-width: 0;
        }
    }

    & & {
        margin: 0 calc(var(--bazis-grid-column-padding-end) * -1) 0
            calc(var(--bazis-grid-column-padding-start) * -1);

        > bazis-row,
        > * > bazis-row {
            bazis-col {
                --bazis-grid-column-padding-start: var(--bazis-padding-3x);
                --bazis-grid-column-padding-end: var(--bazis-padding-3x);
                --bazis-grid-column-padding-top: var(--bazis-padding-3x);
                --bazis-grid-column-padding-bottom: var(--bazis-padding-3x);
            }

            &:first-child {
                bazis-col {
                    --bazis-grid-column-padding-top: 0;
                }
            }

            &:last-child {
                border-bottom: 0;

                bazis-col {
                    --bazis-grid-column-padding-bottom: 0;
                }
            }

            &.sl-tr_no-border {
                border-bottom: 0;
            }
        }
    }

    &.sl-grid_table_hoverable {
        > bazis-row,
        *:not(bazis-row) bazis-row {
            &.sl-row_tbody:hover {
                background: var(--bazis-background-accent);

                .sl-row__title {
                    --color: var(--bazis-link-hover-color);
                    color: var(--bazis-link-hover-color);
                }
            }
        }
    }
}

bazis-grid.sl-grid_table {
    .sl-dropdown_action {
        bazis-button.bazis-dropdown__trigger {
            --color-hover: var(--bazis-color-action);

            bazis-icon {
                color: currentColor;
            }
        }

        &:hover {
            bazis-button.bazis-dropdown__trigger {
                --color: var(--color-hover);
            }
        }
    }

    .bazis-dropdown {
        bazis-vehicle-number + bazis-button.bazis-dropdown__trigger {
            --background: transparent;
            --background-hover: transparent;
            position: relative;
            top: -4px;
        }
    }

    .sl-btn-expanded {
        position: relative;
        top: -9px;
    }

    > bazis-row,
    *:not(bazis-row) > bazis-row {
        > bazis-col {
            .bazis-dropdown {
                &__trigger {
                    --background: var(--bazis-color-white);
                    --color: var(--bazis-placeholder-color);
                }
                &__body {
                    --top: 36px;
                }

                &_vehicle {
                    .bazis-dropdown__trigger {
                        --color: var(--bazis-color-base);
                    }
                }
            }

            bazis-button bazis-icon {
                --bazis-icon-s-size: 16px;
            }

            bazis-button:not(bazis-button.bazis-color) bazis-icon {
                color: var(--bazis-placeholder-color);
            }

            .sl-tr-collapsed {
                cursor: pointer;

                bazis-icon {
                    font-size: 8px;
                    color: var(--bazis-text-color);
                }
            }

            bazis-status {
                display: block;
                margin-top: var(--bazis-margin-2x);
            }
        }
    }

    //D-n-d
    .cdk-drag-placeholder {
        bazis-row {
            background: var(--bazis-background-medium);
        }
        bazis-col {
            opacity: 0;
        }

        &:first-child {
            border-top: 1px solid var(--bazis-border-color);
        }
    }

    .cdk-drag > bazis-row:hover {
        cursor: grab;
    }

    .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }

    .cdk-drop-list-dragging {
        app-rules-list-single-item {
            display: block;
            transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);

            bazis-button {
                pointer-events: none;
                cursor: default;
            }
        }
    }

    &.cdk-drag-preview {
        display: block;
        max-width: 1440px;
        width: 100%;
        background: var(--bazis-background);
        border: 1px solid var(--bazis-border-color);
        border-radius: var(--bazis-border-radius);
        box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.10);
        pointer-events: auto !important;
        cursor: grabbing !important;

        bazis-row {
            pointer-events: none;
            border: none;
        }

        @media (min-width: 1199.98px) {
            max-width: 1120px;
        }

        @media (min-width: 1279.98px) {
            max-width: 1200px;
        }

        @media (min-width: 1359.98px) {
            max-width: 1280px;
        }

        @media (min-width: 1436px) {
            max-width: 1360px;
        }

        @media (max-width: 1435px) {
            margin: 0 -40px !important;
            width: calc(100% - 80px);
        }

        @media (max-width: 1024px) {
            margin: 0 -32px !important;
            width: calc(100% - 64px);
        }

        @media (max-width: 576.98px) {
            margin: 0 -20px !important;
            width: calc(100% - 40px);
        }
    }
}

bazis-grid.sl-grid_table.sl-grid_document-item {
    --bazis-grid-columns: 17;

    bazis-grid.sl-grid_table & > bazis-row {
        &:first-child bazis-col,
        &:last-child bazis-col {
            min-height: 24px;
        }
        &:first-child bazis-col {
            --bazis-grid-column-padding-top: 0;
        }
        &:last-child bazis-col {
            --bazis-grid-column-padding-bottom: 0;
        }

        bazis-col {
            --bazis-grid-column-padding-bottom: 2px;
            --bazis-grid-column-padding-top: 2px;
            min-height: 40px;
            line-height: 24px;
        }
    }

    & > bazis-row.sl-row_tbody:hover {
        background: var(--bazis-background-medium);
    }

    app-payment-document-list-single-item & {
        --bazis-grid-columns: 19;
    }
}

.sl-td_document-item {
    position: relative;

    > .bh-flex {
        position: absolute;
        top: 0;
        left: -38px;
        width: 38px;
        height: 24px;
        align-items: center;
        gap: calc(var(--bazis-margin-6x) - 6px);
        padding-left: var(--bazis-padding-1x);

        .sl-grid_table_hoverable .sl-row_tbody:hover & {
            background: var(--bazis-background-accent);
        }
    }
}

.sl-col_type-document {
    p {
        padding-right: 60px;
        span,
        bazis-button {
            display: inline-block;
            vertical-align: middle;
        }
    }
}
